import { Stack } from '@chakra-ui/react';

import { Image } from '@/components';
import { useProfile } from '@/hooks';
import DesktopFemaleBirthday from '@/images/birthday-female-desktop.gif';
import MobileFemaleBirthday from '@/images/birthday-female-mobile.gif';
import DesktopMaleBirthday from '@/images/birthday-male-desktop.gif';
import MobileMaleBirthday from '@/images/birthday-male-mobile.gif';
import DesktopNewYear from '@/images/newyear-desktop.png';
import MobileNewYear from '@/images/newyear-mobile.png';
import DesktopWedding from '@/images/wedding-anniversary-desktop.gif';
import MobileWedding from '@/images/wedding-anniversary-mobile.gif';

const EventBar: React.FC = () => {
  const { data: profile } = useProfile();

  const hasEvent = true;
  const activeEvent = [
    {
      isActive: profile?.is_birthdate_anniversary,
      mobileIcon:
        profile?.gender === 'male' ? MobileMaleBirthday : MobileFemaleBirthday,
      desktopIcon:
        profile?.gender === 'male'
          ? DesktopMaleBirthday
          : DesktopFemaleBirthday,
      bgColor:
        profile?.gender === 'male'
          ? 'anniversary.menBirthday'
          : 'anniversary.womenBirthday',
    },
    {
      isActive: profile?.is_marriage_anniversary,
      mobileIcon: MobileWedding,
      desktopIcon: DesktopWedding,
      bgColor: 'anniversary.wedding',
    },
    {
      isActive: true,
      mobileIcon: MobileNewYear,
      desktopIcon: DesktopNewYear,
      bgColor: 'anniversary.newYear',
    },
  ];

  if (!hasEvent) {
    return null;
  }

  const event = activeEvent.find((e) => e.isActive);

  return (
    <Stack w="100%" align="center" justify="center" bgColor={event?.bgColor}>
      <Stack maxW="1440px" mx="auto">
        <Stack display={{ base: 'none', sm: 'flex' }} m="auto" w="full">
          <Image
            src={event?.desktopIcon || ''}
            alt="gif"
            style={{ width: 'auto', height: '60px', objectFit: 'cover' }}
          />
        </Stack>
        <Stack display={{ base: 'flex', sm: 'none' }} m="auto" w="full">
          <Image
            src={event?.mobileIcon || ''}
            alt="gif"
            style={{ width: 'auto', height: '60px', objectFit: 'cover' }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventBar;
